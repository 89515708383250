import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import questionGraphic from "./images/question-mark-circle-4-128.png";
import styles from "./Interview.module.css";

export default function ExtraWithholding({
  extraWithholdingCloseButton,
  handleChange,
  formValues,
}) {
  const [withholdingAmount, setWithholdingAmount] = useState({});
  const [showSpouseSection, setShowSpouseSection] = useState(
    formValues.taxFilingStatus
  );

  useEffect(() => {
    setWithholdingAmount({
      withholdingPrimary:
        formValues.withholdingPrimary ||
        formValues.withholdingPrimaryRecommended,

      withholdingSpouse:
        formValues.withholdingSpouse || formValues.withholdingSpouseRecommended,
    });
  }, [formValues]);

  const extraWithholdingAlert = () => {
    alert(
      "In this step, enter how much additional tax you want to be withheld from your pay each pay period. Entering an amount here will reduce your paycheck and will either increase your refund or reduce any amount of tax that you owe. If you are married and have two incomes, you'll see the amount we recommend you withhold from your and your spouse's paycheck each pay period."
    );
  };

  const incomePercentage = () => {
    return (
      (Number(formValues.income1) /
        (Number(formValues.income1) + Number(formValues.income2))) *
      100
    );
  };

  const primaryPercentage = incomePercentage().toFixed(0);

  const spouseIncomePercentage = () => {
    return (
      (Number(formValues.income2) /
        (Number(formValues.income1) + Number(formValues.income2))) *
      100
    );
  };

  const spousePercentage = spouseIncomePercentage().toFixed(0);

  const handleChangeLocal = (evt) => {
    const { name, value } = evt.target;
    setWithholdingAmount((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    handleChange(withholdingAmount);
    ReactGA.event({
      category: "User",
      action: "Completed extra withholding",
    });
    extraWithholdingCloseButton();
  };
  const recPrimaryWithholdingPerPaycheck =
    formValues.withholdingPrimaryRecommended;
  const recSpouseWithholdingPerPaycheck =
    formValues.withholdingSpouseRecommended;
  return (
    <form onSubmit={handleSubmit}>
      {!showSpouseSection && (
        <div className={styles.questionContainer}>
          <div className={styles.headerContainer}>
            <h3>Extra WithHolding</h3>
            <img
              onClick={extraWithholdingAlert}
              alt="question mark"
              className={styles.question}
              src={questionGraphic}
            />
          </div>
          <p>
            {`Based on your wages, the IRS tables on page 4 of the W-4 instructions recommend
          you withhold an extra $${formValues?.withholdingAmount} per year. Please note this is only one component of your withholding. Your employer will calculate the other component. `}
          </p>
          <div className={styles.alignLeft}>
            <p>
              {`Amount to withhold per pay period on the Spouse 1 primary return. We suggest
                  allocating according to your percentage of total income or 
                  ${primaryPercentage}%. That would be $${recPrimaryWithholdingPerPaycheck} per paycheck for the primary taxpayer.`}
            </p>

            <input
              name="withholdingPrimary"
              value={withholdingAmount.withholdingPrimary || ""}
              onChange={handleChangeLocal}
              onFocus={(e) => {
                e.target.value = "";
              }}
            />
            <div className={styles.nextButton}>
              <button type="submit">NEXT</button>
            </div>
          </div>
        </div>
      )}

      {showSpouseSection && (
        <div className={styles.questionContainer}>
          <div className={styles.headerContainer}>
            <h3>Extra WithHolding</h3>
            <img
              onClick={extraWithholdingAlert}
              alt="question mark"
              className={styles.question}
              src={questionGraphic}
            />
          </div>
          <p>
            {`Based on your wages, the IRS tables on page 4 of the W-4 instructions recommend
          you withhold an extra $${formValues?.withholdingAmount} per year. The recommended allocations are listed below. You can change them according to your needs. If you reduce the recommended amounts, you may not be withholding enough. Please note this is only one component of your withholding. Your employer will calculate the other component.`}
          </p>
          <div className={styles.alignLeft}>
            <p>
              {`Amount to withhold per pay period on the Spouse 1 primary return. We suggest
                  allocating according to your percentage of total income or 
                  ${primaryPercentage}%. That would be $${recPrimaryWithholdingPerPaycheck} per paycheck for the primary taxpayer.`}
            </p>

            <input
              name="withholdingPrimary"
              value={withholdingAmount.withholdingPrimary || ""}
              onChange={handleChangeLocal}
              onFocus={(e) => {
                e.target.value = "";
              }}
            />
          </div>
          {formValues.processSpouseW4 && (
            <div className={styles.alignLeft}>
              <br />

              <p>
                {`Amount to withhold per pay period on Spouse 2's Form W-4. We suggest
                  allocating according to Spouse 2's percentage of total income or 
                  ${spousePercentage}%. That would be $${recSpouseWithholdingPerPaycheck} per paycheck for the secondary taxpayer.`}
              </p>

              <input
                name="withholdingSpouse"
                value={withholdingAmount.withholdingSpouse || ""}
                onChange={handleChangeLocal}
                onFocus={(e) => {
                  e.target.value = "";
                }}
              />
            </div>
          )}

          <div className={styles.nextButton}>
            <button type="submit">NEXT</button>
          </div>
        </div>
      )}
    </form>
  );
}
