import React from "react";
import styles from "./Footer.module.css";

export default function Footer() {
  return (
    <div className={styles.footerContainer}>
      <div>
        <p
          style={{ marginBottom: 0, cursor: "pointer" }}
          onClick={() => window.open("/privacy-policy", "_blank")}
        >
          Privacy Policy
        </p>
        <p
          style={{ marginTop: 0, cursor: "pointer" }}
          onClick={() => window.open("/terms-of-service", "_blank")}
        >
          Terms of Service
        </p>
      </div>
      <div className={styles.copyright}>
        <p style={{ marginBottom: 0, cursor: "pointer" }}>
          Copyright © 2024 by TaxFluent
        </p>
        <p
          style={{ marginTop: 0, cursor: "pointer" }}
          onClick={() => window.open("mailto:info@w-4married.com")}
        >
          info@w-4married.com
        </p>
      </div>
    </div>
  );
}
