// updated 2/23/24
const marriedTable = [
  [0, 0, 780, 850, 940, 1020, 1020, 1020, 1020, 1020, 1020, 1370],
  [0, 780, 1780, 1940, 2140, 2220, 2220, 2220, 2220, 2220, 2570, 3570],
  [780, 1780, 2870, 3140, 3340, 3420, 3420, 3420, 3420, 3770, 4770, 5770],
  [850, 1940, 3140, 3410, 3610, 3690, 3690, 3690, 4040, 5040, 6040, 7040],
  [940, 2140, 3340, 3610, 3810, 3890, 3890, 4240, 5240, 6240, 7240, 8240],
  [1020, 2220, 3420, 3690, 3890, 3970, 4320, 5320, 6320, 7320, 8320, 9320],
  [1020, 2220, 3420, 3690, 3890, 4320, 5320, 6320, 7320, 8320, 9320, 10320],
  [1020, 2220, 3420, 3690, 4240, 5320, 6320, 7320, 8320, 9320, 10320, 11320],
  [1020, 2220, 3620, 4890, 6090, 7170, 8170, 9170, 10170, 11170, 12170, 13170],
  [
    1870, 4070, 6270, 7540, 8740, 9820, 10820, 11820, 12830, 14030, 15230,
    16430,
  ],
  [
    1960, 4360, 6760, 8230, 9630, 10910, 12110, 13310, 14510, 15710, 16910,
    18110,
  ],
  [
    2040, 4440, 6840, 8310, 9710, 10990, 12190, 13390, 14590, 15790, 16990,
    18190,
  ],
  [
    2040, 4440, 6840, 8310, 9710, 10990, 12190, 13390, 14590, 15790, 16990,
    18190,
  ],
  [
    2040, 4440, 6840, 8310, 9710, 10990, 12190, 13390, 14590, 15790, 16990,
    18380,
  ],
  [
    2040, 4440, 6840, 8310, 9710, 10990, 12190, 13390, 14590, 15980, 17980,
    19980,
  ],
  [
    2040, 4440, 6840, 8310, 9710, 11280, 13280, 15280, 17280, 19280, 21280,
    23280,
  ],
  [
    2720, 6010, 9510, 12080, 14580, 16950, 19250, 21550, 23850, 26150, 28450,
    30750,
  ],
  [
    3140, 6840, 10540, 13310, 16010, 18590, 21090, 23590, 26090, 28590, 31090,
    33590,
  ],
];

// TODO: remember the last case needs to be over 110,000
const marriedColumns = [
  9999, 19999, 29999, 39999, 49999, 59999, 69999, 79999, 89999, 99999, 109999,
  120000,
];

// TODO: remember the last case needs to be over 525,000
const marriedRows = [
  9999, 19999, 29999, 39999, 49999, 59999, 69999, 79999, 99999, 149999, 239999,
  259999, 279999, 299999, 319999, 364999, 524999,
];

const marriedCalculation = (income1, income2) => {
  const higherNumber = Math.max(income1, income2);
  const lowerNumber = Math.min(income1, income2);

  let rowIndex = marriedRows.findIndex((range) => higherNumber <= range);
  let columnIndex = marriedColumns.findIndex((range) => lowerNumber <= range);

  // If income is higher than all ranges, use the highest available index
  if (rowIndex === -1) {
    rowIndex = marriedRows.length;
  }

  if (columnIndex === -1) {
    columnIndex = marriedColumns.length;
  }

  // Ensure the indices are within bounds of the marriedTable
  rowIndex = Math.min(rowIndex, marriedTable.length - 1);
  columnIndex = Math.min(columnIndex, marriedTable[rowIndex].length - 1);

  return marriedTable[rowIndex][columnIndex];
};

export { marriedCalculation };
