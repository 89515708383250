import React, { useState } from "react";
import ReactGA from "react-ga4";
import questionGraphic from "./images/question-mark-circle-4-128.png";
import styles from "./Interview.module.css";

export default function OtherIncome({
  otherIncomeCloseButton,
  handleChange,
  formValues,
}) {
  const [income, setIncome] = useState();
  const otherIncomeAlert = () => {
    alert(
      "Enter in this step the total of your other estimated income for the year, if any. You shouldn’t include income from any jobs or self-employment."
    );
  };
  const makeChange = (evt) => {
    setIncome({
      ...income,
      [evt.target.name]: evt.target.value,
    });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    handleChange(income);
    ReactGA.event({
      category: "User",
      action: "Completed other income",
    });
    otherIncomeCloseButton();
  };
  return (
    <div className={styles.questionContainer}>
      <div className={styles.headerContainer}>
        <h3>Other Income</h3>
        <img
          onClick={otherIncomeAlert}
          alt="question mark"
          className={styles.question}
          src={questionGraphic}
        />
      </div>
      <div onClick={makeChange}>
        <form onSubmit={handleSubmit}>
          {formValues.processSpouseW4 ? (
            <div className={styles.container}>
              <div className={styles.row}>
                <p>On Spouse 1's Form W-4</p>
                <input
                  defaultValue={formValues.otherIncome}
                  name="otherIncome"
                  onChange={makeChange}
                  onFocus={(e) => {
                    e.target.value = "";
                  }}
                ></input>
              </div>

              <div className={styles.row}>
                <p>On Spouse 2's Form W-4</p>
                <input
                  defaultValue={formValues.otherIncomeSpouse}
                  name="otherIncomeSpouse"
                  onChange={makeChange}
                  onFocus={(e) => {
                    e.target.value = "";
                  }}
                ></input>
              </div>
            </div>
          ) : (
            <input
              name="otherIncome"
              defaultValue={formValues.otherIncome}
              onChange={makeChange}
              onFocus={(e) => {
                e.target.value = "";
              }}
            ></input>
          )}
          <div className={styles.nextButton}>
            <button onClick={handleSubmit}>NEXT</button>
          </div>
        </form>
      </div>
    </div>
  );
}
