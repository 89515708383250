import React from "react";
import Footer from "./Footer.js";
import styles from "./Interview.module.css";
import Header from "./Header.js";

export default function TermsOfService() {
  return (
    <div>
      <Header />
      <div className={styles.toc}>
        <p>
          PLEASE CAREFULLY READ THESE TERMS OF USE, ALONG WITH THE PRIVACY
          POLICY AND OTHER POLICIES OR AGREEMENTS REFERENCED IN THESE TERMS OF
          USE, BEFORE USING THE "COMPANY SERVICES" (AS DEFINED BELOW).
        </p>
        <h3>
          Description of Company Services and Acceptance of Terms of Use
          Including Arbitration of Disputes
        </h3>
        <p>
          W4married.com ("Company, "we" or "us") has developed these Terms of
          Service to describe the terms that govern your use of W4married.com
          web user site and mobile application and other content and services
          that link to these Terms of Service (collectively the "Company
          Sites"). (AND collectively, the "Company Services"). Company is based
          in the United States and the Company Services are hosted in the United
          States.
        </p>
        <p>
          Company furnishes the Company Sites and the Company Services for your
          personal use only. By visiting the Company Sites (whether or not you
          are a registered member) or using the Company Services, you accept and
          agree to be bound by this Agreement, including any future
          modifications ("Agreement"), and to abide by all applicable laws,
          rules and regulations ("Applicable Law"). Please read through this
          Agreement carefully. Company may modify this Agreement at any time,
          and each such modification will be effective upon posting on the
          Company Sites. All material modifications will apply prospectively
          only. Your continued use of the Company Sites or the Company Services
          following any modification of this Agreement constitutes your
          acceptance of and agreement to be bound by the Agreement as modified.
          It is therefore important that you review this Agreement regularly. If
          you do not agree to be bound by this Agreement and to abide by all
          Applicable Law, you must discontinue use of the Company Services
          immediately.
        </p>

        <p>
          No liability - Company makes no warranty or representation and accepts
          no liability for any loss, taxes, penalties, or damages whatsoever,
          relating to or in connection with your use of the program or Site
          Product. Company, makes no warranty for, and to the fullest extent
          provided by law, accepts no liability regarding your use of the
          program or Company Sites. You are solely responsible for any and all
          forms and transactions and results utilizing your Personal Financial
          Information, including, but not limited to, any and all charges and
          taxes. You acknowledge and agree that, to the fullest extent permitted
          by law, you accept personal responsibility for your own tax liability,
          and Personal Financial Information. Company will in no way be
          responsible or liable to you for any tax liability, penalties, fees,
          errors or omissions.
        </p>
        <h3>Third Party Links and Services</h3>
        <p>
          The Company Services may provide, or third parties may provide, links
          to other websites, advertisers, applications, resources or other
          services created by third parties ("Third Party Services"). When you
          engage with a provider of a Third Party Service, you are interacting
          with the third party, not with Company. If you choose to use a Third
          Party Service and share information with it, the provider of the Third
          Party Service may use and share your data in accordance with its
          privacy policy and your privacy settings on such Third Party Service.
          Company encourages you not to provide any personally identifiable
          information to or through any Third Party Service unless you know and
          are comfortable with the party with whom you are interacting. In
          addition, the provider of the Third Party Service may use other
          parties to provide portions of the application or service to you, such
          as technology, development or payment services. Company is not
          responsible for and makes no warranties, express or implied, as to the
          Third Party Services or the providers of such Third Party Services
          (including, but not limited to, the accuracy or completeness of the
          information provided by such Third Party Service or the privacy
          practices thereof). Inclusion of any Third Party Service or a link
          thereto on the Company Services does not imply approval or endorsement
          of the Third Party Service. Company is not responsible for the content
          or practices of any websites other than the Company Sites, even if the
          website links to the Company Sites and even if it is operated by a
          Company Affiliate or a company otherwise connected with the Company
          Sites. By using the Company Services, you acknowledge and agree that
          Company is not responsible or liable to you for any content or other
          materials hosted and served from any website other than the Company
          Sites. When you access Third Party Services, you do so at your own
          risk. If you are interested in creating hypertext links to the Company
          Sites, you must contact Company's legal department.
        </p>
        <h3>Member Disputes</h3>
        <p>
          You are solely responsible for your interactions with other users of
          the Company Sites and the Company Services, providers of Third Party
          Services or any other parties with whom you interact on, through or in
          connection with the Company Services. Company reserves the right, but
          has no obligation, to become involved in any way with any disputes
          between you and such parties.
        </p>
        <h3>Trademarks</h3>
        <p>
          Company, the Company logo, and other Company marks, graphics, logos,
          scripts, and sounds are trademarks of Company. None of the Company
          trademarks may be copied, downloaded, or otherwise exploited.
        </p>
        <h3>Disclaimer</h3>
        <p>
          THE COMPANY SERVICES ARE PROVIDED "AS-IS" AND "AS AVAILABLE" AND
          COMPANY DOES NOT GUARANTEE OR PROMISE ANY SPECIFIC RESULTS FROM USE OF
          OR CONTINUOUS AVAILABILITY OF THE COMPANY SERVICES. TO THE FULLEST
          EXTENT PERMITTED BY APPLICABLE LAW, COMPANY EXPRESSLY DISCLAIMS ANY
          WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
          INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
          NON-INFRINGEMENT, AND WARRANTIES IMPLIED FOR A COURSE OF PERFORMANCE
          OR COURSE OF DEALING. WITHOUT LIMITING THE GENERALITY OF THE
          FOREGOING, COMPANY MAKES NO WARRANTY THAT YOUR USE OF THE COMPANY
          SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE, THAT
          DEFECTS TO THE COMPANY SERVICES WILL BE CORRECTED, THAT THE COMPANY
          SERVICES OR THE SERVERS ON WHICH THEY ARE AVAILABLE WILL BE FREE OF
          VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT ANY INFORMATION OBTAINED
          BY YOU ON, THROUGH OR IN CONNECTION WITH THE COMPANY SERVICES OR THIRD
          PARTY SERVICES (INCLUDING, BUT NOT LIMITED TO, THROUGH USER CONTENT OR
          THIRD PARTY ADVERTISEMENTS) WILL BE ACCURATE, RELIABLE, TIMELY OR
          COMPLETE. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE
          COMPANY WILL NOT BE RESPONSIBLE FOR ANY LOSS OR DAMAGE (INCLUDING BUT
          NOT LIMITED TO LOSS OF DATA, PROPERTY DAMAGE, PERSONAL INJURY OR
          DEATH) RESULTING FROM USE OF THE COMPANY SERVICES, PROBLEMS OR
          TECHNICAL MALFUNCTION IN CONNECTION WITH USE OF THE COMPANY SERVICES,
          ATTENDANCE AT A COMPANY EVENT, ANY MATERIAL DOWNLOADED OR OTHERWISE
          OBTAINED IN CONNECTION WITH THE COMPANY SERVICES, ANY USER CONTENT,
          ANY THIRD PARTY ADVERTISEMENT OR THIRD PARTY SERVICE TRANSMITTED ON,
          THROUGH OR IN CONNECTION WITH THE COMPANY SERVICES, OR THE CONDUCT OF
          ANY USERS OF THE COMPANY SERVICES, WHETHER ONLINE OR OFFLINE. YOUR USE
          OF USER CONTENT, THIRD PARTY ADVERTISEMENTS, THIRD PARTY SERVICES.
        </p>
        <h3>Prohibited Uses</h3>
        <p>Users are explicitly prohibited from:</p>
        <ul>
          <li>
            leasing, selling, copying, sublicensing, transferring, or assigning
            any information, intellectual property, goods, or services provided
            on the site
          </li>
          <li>using the site for any illegal purpose</li>
          <li>
            gaining unauthorized access to the company's data or the data of
            other users
          </li>
          <li>
            altering, modifying, adapting, reverse engineering, decompiling,
            disassembling, or hacking the company's intellectual property
          </li>
          <li>
            altering or modifying another website to falsely imply that it is
            associated with the company's website,
          </li>
          <li>
            using or exporting the company's information, products, or services
            in violation of U.S. export laws and regulations
          </li>
          <li>
            violating anyone else's legal rights (for example, privacy rights)
            or any laws (for example, copyright laws) in the user's jurisdiction
          </li>
          <li>
            using the website or the company's services to transmit content that
            could be deemed unlawful, threatening, harassing, racist, abusive,
            libelous, pornographic, vulgar, defamatory, obscene, indecent, or
            otherwise inappropriate, including any messages constituting or
            encouraging criminal conduct
          </li>
          <li>
            breaching, or attempting to breach, the website's security systems
          </li>
          <li>enabling third parties to violate the Terms of Use, and</li>
          <li>
            (if legally required) failing to ensure that all end users of the
            site are at least 18 years of age or older.
          </li>
        </ul>
        <h3>Service Interruptions and Updates</h3>
        <p>
          Any website can suffer either scheduled interruptions (for maintenance
          and upgrades purposes) or unintended shutdowns (for whatever reason).
          As a user you are hereby notified of these possibilities and the
          Company offers no compensation for data loss or other consideration
          for these issues, whether it by a commitment to notify the user prior
          to any interruptions or to restore service within a certain time
          period.
        </p>
        <h3>Term, Termination, and Survival</h3>
        <p>
          Terms of Use exclusively states the agreement created between the user
          and the company begins the moment the user commences interacting with
          the website or uses any of its goods or services; the contractual
          relationship ends when the user is no longer availing itself of the
          site.{" "}
        </p>
        <p>
          "This Agreement shall be effective as of the date (the "Effective
          Date") the User accepts the terms herein or first accesses, downloads
          or uses any of the services or information (collectively, the
          "Services") on the site and shall remain in effect for so long as the
          User uses or accesses any of the Services (the "Term"). Upon
          termination of the Term, the User shall no longer be permitted to use
          or access the Services. The terms herein that contemplate obligations
          after the Term, including but not limited to Indemnification,
          Disclaimer, Limitation of Liability, Controlling Law and Severability,
          and Confidentiality, shall survive termination."
        </p>
      </div>
      <Footer />
    </div>
  );
}
