// updated 2/26/24
const householdTable = [
  [0, 510, 850, 1020, 1020, 1020, 1020, 1220, 1870, 1870, 1870, 1960],
  [510, 1510, 2020, 2220, 2220, 2220, 2420, 3420, 4070, 4070, 4160, 4360],
  [850, 2020, 2560, 2760, 2760, 2960, 3960, 4960, 5610, 5700, 5900, 6100],
  [1020, 2220, 2760, 2960, 3160, 4160, 5160, 6160, 6900, 7100, 7300, 7500],
  [1020, 2220, 2810, 4010, 5010, 6010, 7070, 8270, 9120, 9320, 9520, 9720],
  [1070, 3270, 4810, 6010, 7070, 8270, 9470, 10670, 11520, 11720, 11920, 12120],
  [
    1870, 4070, 5670, 7070, 8270, 9470, 10670, 11870, 12720, 12920, 13120,
    13450,
  ],
  [
    2020, 4420, 6160, 7560, 8760, 9960, 11160, 12360, 13210, 13880, 14880,
    15880,
  ],
  [
    2040, 4440, 6180, 7580, 8780, 9980, 11250, 13250, 14900, 15900, 16900,
    17900,
  ],
  [
    2040, 4440, 6180, 7580, 9250, 11250, 13250, 15250, 16900, 18030, 19330,
    20630,
  ],
  [
    2040, 4510, 7050, 9250, 11250, 13250, 15250, 17530, 19480, 20780, 22080,
    23380,
  ],
  [
    2720, 5920, 8620, 11120, 13420, 15720, 18020, 20320, 22270, 23570, 24870,
    26170,
  ],
  [
    2970, 6470, 9310, 11810, 14110, 16410, 18710, 21010, 22960, 24260, 25560,
    26860,
  ],
  [
    3140, 6840, 9880, 12580, 15080, 17580, 20080, 22580, 24730, 26230, 27730,
    29230,
  ],
];
// TODO: remember the last case needs to be over 110,000
const householdColumns = [
  9999, 19999, 29999, 39999, 49999, 59999, 69999, 79999, 89999, 99999, 109999,
  120000,
];

// TODO: remember the last case needs to be over 450,000
const householdRows = [
  9999, 19999, 29999, 39999, 59999, 79999, 99999, 124999, 149999, 174999,
  199999, 249999, 450000,
];

const householdCalculation = (income1, income2) => {
  const higherNumber = Math.max(income1, income2);
  const lowerNumber = Math.min(income1, income2);

  let rowIndex = householdRows.findIndex((range) => higherNumber <= range);
  let columnIndex = householdColumns.findIndex((range) => lowerNumber <= range);

  // If income is higher than all ranges, use the highest available index
  if (rowIndex === -1) {
    rowIndex = householdRows.length;
  }

  if (columnIndex === -1) {
    columnIndex = householdColumns.length;
  }

  // Ensure the indices are within bounds of the singleTable
  rowIndex = Math.min(rowIndex, householdTable.length - 1);
  columnIndex = Math.min(columnIndex, householdTable[rowIndex].length - 1);

  return householdTable[rowIndex][columnIndex];
};

export { householdCalculation };
