import React from "react";
import Footer from "./Footer.js";
import styles from "./Interview.module.css";
import Header from "./Header.js";

export default function PrivacyPolicy() {
  return (
    <>
      <div id="page-container">
        <Header />
        <div className={styles.toc}>
          <h3>Personal Information We Collect</h3>
          <p>
            Information you provide to us. We do collect the following types of
            personal information about you that you provide to us when you use
            the Service:
          </p>
          <ul>
            <li>
              <b>Contact data,</b> such as your first and last name, email
              address, mailing addresses, phone number.
            </li>
            <li>
              <b>Registration data,</b> such as information that you submit when
              you complete an online registration form to access and use certain
              features of our Services (e.g., our email newsletters), sign up
              for an audiocast or podcast, or enter contests, sweepstakes or
              promotions.
            </li>
            <li>
              <b>Communications</b> that we exchange when you contact us with
              questions, feedback, or otherwise.
            </li>
            <li>
              <b>Marketing data,</b> such as the email address or contact
              details that we use to send marketing communications to you, your
              marketing preferences, and information about your responses to our
              marketing communications.
            </li>
            <li>
              <b>Other information.</b> We may also collect other categories of
              personal information for uses in accordance with this Privacy
              Policy or otherwise described at the time of collection. (see
              below)
            </li>
          </ul>
          <p>
            <b>
              <u>We do</u>
            </b>{" "}
            collect filing status information, zip code and income for
            advertising purposes for target marketing for our advertisers. Your
            personal information is not shared with advertisers.
          </p>
          <p>
            <b>Data from other sources.</b> We may combine personal information
            we receive from you with personal information we obtain from other
            sources, such as:
          </p>

          <ul>
            <li>
              <b>Content partners,</b> such as internet streaming platforms and
              networks with which we have relationships, that provide us
              information about their streaming audiences’ devices and viewing
              activity, which we use to help our advertisers deliver advertising
              that is more likely to be relevant to their viewers’ interests.
            </li>
            <li>
              <b>Data providers,</b> such as information services and data
              licensors.
            </li>
            <li>
              <b>Publicly-available sources,</b> such as social media platforms.
            </li>
            <li>
              <b>Business partners,</b> such as joint marketing partners and
              event co-sponsors.
            </li>
          </ul>
          <p>
            <b>Data collected automatically.</b> We, our service providers, and
            third-party partners may automatically log demographic information
            about you, your computer or mobile device, and your activity over
            time on the Services and other online services, such as:
          </p>
          <ul>
            <li>
              <b>Device data,</b> such as your computer or mobile device
              operating system type and version number, manufacturer and model,
              browser type, screen resolution, IP address, unique identifiers
              (e.g., Apple’s ID for Advertising or Google’s Advertising ID),
              general location information (such as city or state), and precise
              geolocation (if you permit our Services to access it from your
              device settings).
            </li>
            <li>
              <b>Online activity data,</b> such as the website you visited
              before browsing to our website, pages, or screens you viewed on
              the Services, how long you spent on a page or screen, navigation
              paths between pages or screens, information about your activity on
              a page or screen, access times, and duration of access.
            </li>
          </ul>
          <p>
            <b>Cookies.</b> Some of our automatic data collection is facilitated
            by cookies and other technologies. Cookies are small data files
            placed on your computer when you visit a site. We may set cookies
            when you use the Services to track user trends and patterns, help
            you navigate between pages efficiently, remember your preferences
            and generally improve your browsing experience. Cookies used on the
            Services may also be set by analytics service providers and
            advertising partners. In addition to cookies, the Services and our
            emails may use pixel tags (also known as web beacons and clear GIFs)
            to compile statistics about use of the Services, measure the success
            of our marketing campaigns, and indicate whether recipients of our
            emails open or click links within them.
          </p>
          <h3>How We Use Your Personal Information</h3>
          <p>
            We use your personal information for the following purposes and as
            otherwise described in this Privacy Policy or at the time of
            collection:
          </p>
          <p>
            <b>Service delivery.</b> We may use your personal information to:
          </p>
          <ul>
            <li>provide, operate and improve the Services;</li>
            <li>
              communicate with you about the Services, including by sending you
              announcements, updates, and support and administrative messages;
              and
            </li>
            <li>
              provide support for the Services, and respond to your requests,
              questions, and feedback.
            </li>
          </ul>
          <p>
            <b>Research and development.</b> We may use your personal
            information to administer surveys and other market research and for
            other research and development purposes, including to analyze and
            improve the Services and our business. As part of these activities,
            we may create anonymous data (including aggregated and/or
            de-identified data) from personal information we collect. We convert
            personal information we collect into anonymous data by removing
            information that makes the data personally identifiable to you. We
            will maintain and use deidentified information in deidentified form
            and not attempt to reidentify it, except for purposes of testing to
            ensure that the information has been adequately deidentified. We may
            use this anonymous data and share it with third parties for our
            lawful business purposes, including to analyze and improve the
            Services and promote our business.
          </p>
          <p>
            <b>Marketing and Targeted Advertising.</b> We may send you related
            and or other marketing communications via email and other channels
            as permitted by law. We also work with third-party advertising
            partners who use cookies and other technologies to help us advertise
            services. These advertisements are delivered by our advertising
            partners and may be targeted based on your geographic area, filing
            status and activity on the Services and other online services.{" "}
          </p>
          <p>
            <b>Compliance and operations.</b> We may use your personal
            information to:
          </p>
          <ul>
            <li>operate our business;</li>
            <li>
              comply with applicable laws, lawful requests, and legal process,
              such as to respond to subpoenas or requests from government
              authorities;
            </li>
            <li>
              protect our, your or others’ rights, privacy, safety, or property
              (including by making and defending legal claims);
            </li>
            <li>
              enforce the terms and conditions that govern the Services; and
            </li>
            <li>
              prevent, identify, investigate and deter fraudulent, harmful,
              unauthorized, unethical or illegal activity, including
              cyberattacks and identity theft.
            </li>
          </ul>
          <h3>How We Share Your Personal Information</h3>
          <p>
            We don’t share your personal information. We may use filing status
            and salary information generically to trigger a targeted
            advertisement to you while using our software. To do so we may share
            data with the following parties and as otherwise described in this
            Privacy Policy or at the time of collection:
          </p>
          <p>
            <b>Affiliates.</b> Our corporate parent, subsidiaries, and
            affiliates, for purposes consistent with this Privacy Policy.
          </p>
          <p>
            <b>Service providers.</b> Companies and individuals that provide
            services on our behalf or help us operate the Services or our
            business, such as customer service and support providers; technology
            providers (including technology support, web hosting providers,
            email and text communications providers, and mobile app developers);
            shipping and fulfillment service providers; survey and market
            research providers; advertising and marketing partners; and
            analytics services providers.
          </p>
          <p>
            <b>Advertising partners.</b> Third-party advertising companies:
          </p>
          <ul>
            <li>
              that collect information about your activity on the Services and
              other online services to help us advertise our services; and
            </li>
            <li>
              who use our Premion service to deliver targeted commercials and
              advertisements to our content partners’ viewers, as described
              above.
            </li>
          </ul>
          <p>
            <b>Professional advisors.</b> Professional advisors, such as
            lawyers, auditors, bankers, and insurers, where necessary in the
            course of the professional services that they render to us.
          </p>
          <p>
            <b>Authorities and others.</b> Law enforcement, government
            authorities, central banks and private parties, as we believe
            necessary or appropriate for the compliance and operations purposes
            described above.
          </p>
          <p>
            <b>Business transferees.</b> Acquiring and other relevant parties
            (and their advisors) to business transactions (or potential
            transactions) involving a corporate divestiture, merger,
            consolidation, acquisition, reorganization, sale or other
            disposition of all or any portion of the business, assets, or equity
            interests in TEGNA or our affiliates (including, in connection with
            a bankruptcy or similar proceedings).
          </p>
          <p>
            Please keep in mind that whenever you voluntarily make your personal
            information available for viewing by third parties on our Services,
            that information may be seen, collected and used by others besides
            us. We cannot be responsible for any unauthorized third-party use of
            such information.
          </p>
          <h3>Retention</h3>
          <p>
            The length of time we retain particular categories of personal
            information is determined based on whether it is sufficient to
            fulfill the purposes for which we collected it, including to satisfy
            legal, regulatory, accounting, or reporting requirements, establish
            or defend legal claims, enforce our agreements, resolve disputes or
            fraud prevention. We may either delete or anonymize personal
            information we no longer require.
          </p>
          <h3>Your Choices</h3>
          <p>
            <b>Your privacy rights.</b> You can make certain requests regarding
            your personal information as described in the section below entitled
            “Your Privacy Rights.”
          </p>
          <p>
            <b>Opt out of marketing emails.</b> You may opt out of
            marketing-related emails by following the opt-out or unsubscribe
            instructions at the bottom of the email. You may continue to receive
            Services-related and other non-marketing emails.
          </p>
          <p>
            <b>Text message alerts.</b> We may offer text news alerts for your
            phone or wireless device via short message service (“SMS”) or
            similar technology. You must register with us to receive this
            service, which may require providing us your name, telephone number,
            and other personal information. You can cancel or modify your alerts
            by accessing your account through the Services or by texting “STOP”
            to the number associated with the text news alert. Message and data
            rates may apply for this service.
          </p>
          <p>
            <b>Advertising choices.</b> You can limit use of your information
            for targeted advertising by:
          </p>
          <ul>
            <li>
              Browser settings. Blocking third party cookies in your browser
              settings.
            </li>
            <li>
              Ad industry tools. Opting out of targeted ads from companies
              participating in the following industry opt-out programs:
              <ul>
                <li>
                  Network Advertising Initiative:{" "}
                  <a href="http://www.networkadvertising.org/managing/opt_out.asp">
                    http://www.networkadvertising.org/managing/opt_out.asp
                  </a>
                </li>
                <li>Digital Advertising Alliance: optout.aboutads.info.</li>
                <li>
                  AppChoices mobile app, available at{" "}
                  <a href="https://www.youradchoices.com/appchoices">
                    https://www.youradchoices.com/appchoices
                  </a>
                  , which will allow you to opt-out of targeted ads in mobile
                  apps served by participating members of the Digital
                  Advertising Alliance.
                </li>
              </ul>
            </li>
            <li>
              <b>Mobile settings.</b> Using your mobile device settings to limit
              use of the advertising ID associated with your mobile device for
              targeted advertising purposes.
            </li>
          </ul>
          <p>
            You will need to apply these opt-out settings on each device and in
            each browser from which you wish to opt-out.
          </p>
          <p>
            <b>Do Not Track.</b> Some Internet browsers may be configured to
            send “Do Not Track” signals to the online services that you visit.
            We currently do not respond to “Do Not Track” or similar signals
            except where we expressly indicate otherwise. To find out more about
            “Do Not Track,” please visit{" "}
            <a href="http://www.allaboutdnt.com">http://www.allaboutdnt.com</a>.
          </p>
          <p>
            <b>Cookies.</b> Most browsers let you remove and/or reject cookies.
            To do this, follow the instructions in your browser settings. Many
            browsers accept cookies by default until you change your settings.
            Please note that if you set your browser to disable cookies, our
            Services may not work properly. For more information about cookies,
            including how to see what cookies have been set on your browser and
            how to manage and delete them, visit www.allaboutcookies.org.
          </p>
          <p>
            <b>Declining to provide information.</b> We need to collect personal
            information to provide certain Services. If you do not provide the
            information requested, we may not be able to provide those Services.
          </p>
          <h3>Other Sites and Services</h3>
          <p>
            The Services may contain links to other websites and online services
            operated by third parties. These links are not an endorsement of, or
            representation that we are affiliated with, any third party. In
            addition, our content may be included on web pages or other online
            services that are not associated with us. We do not control third
            party websites or online services, and we are not responsible for
            their actions.
          </p>
          <p>
            We also make our third-party partners’ content, products or services
            available through our Services, whereby our partners’ brands appear
            on the Services in connection with such content, products or
            services. We may share with our partners information you provide, or
            that is collected, in the course of visiting any pages that are made
            available in cooperation with our partner. In some cases, the
            partner may collect information from you directly, in which cases
            the privacy policy of our partner applies to the partner’s use of
            your information. For example, we make our audiocasts available
            through a third-party host. When you register for an audiocast, the
            host will collect information from you directly and will share it
            with us. The host will provide its privacy policy at the point of
            registration.
          </p>
          <p>
            The privacy policy of our partners may differ from ours. If you have
            any questions regarding the privacy policy of one of our partners,
            you should contact the partner directly for more information.
          </p>
          <h3>Security</h3>
          <p>
            The security of your personal information is important to us. We
            employ organizational, technical and physical safeguards designed to
            protect the personal information we collect. However, security risk
            is inherent in all internet and information technologies and we
            cannot guarantee the security of your personal information.
          </p>
          <h3>International Data Transfers</h3>
          <p>
            Our Services are intended to be used only by residents of the United
            States.
          </p>
          <p>
            We are headquartered in the United States, and your personal
            information may be transferred to the United States or other
            locations outside of your state, province, or country where privacy
            laws may not be as protective.
          </p>
          <h3>Children</h3>
          <p>
            Our services are not intended for children under the age of 16, and
            we do not knowingly collect any personal information from a child
            under 16. If we become aware that we have inadvertently received
            personal information from a user under the age of 16 as part of the
            Services, we will delete such information from our records.
          </p>
          <h3>Changes to this Privacy Policy</h3>
          <p>
            We reserve the right to modify this Privacy Policy at any time. If
            we make material changes to this Privacy Policy, we will notify you
            by updating the date of this Privacy Policy and posting it on the
            applicable Service(s). Any modifications to this Privacy Policy will
            be effective upon our posting the modified version (or as otherwise
            indicated at the time of posting). In all cases, your continued use
            of the Services after the posting of any modified Privacy Policy
            indicates your acceptance of the modified Privacy Policy.
          </p>
          <h3>Your Privacy Rights</h3>
          <p>
            Residents of certain states (e.g., California, Virginia) have the
            rights described below under state privacy laws. However, we honor
            requests described in this section from all users of our digital
            properties who reside in the United States, regardless of your state
            of residence.
          </p>
          <p>
            For purposes of this section, “personal information” has the meaning
            given to “personal data”, “personal information” or similar terms
            under the applicable privacy laws of the state in which you reside.
          </p>
          <p>You have the following rights:</p>
          <ul>
            <li>
              <b>Information.</b> You have the right to know what personal
              information we have collected about you, including the categories
              of personal information, the categories of sources from which the
              personal information is collected, the business or commercial
              purpose for collecting and any selling or sharing personal
              information, the categories of personal information that we sold
              or disclosed for a business purpose, and the categories of third
              parties to whom we disclosed, sold or shared the personal
              information.
            </li>
            <li>
              <b>Access.</b> You can request a copy of certain personal
              information that we have collected about you in a structured,
              machine-readable and commonly used format.
            </li>
            <li>
              <b>Deletion.</b> You can ask us to delete certain personal
              information that we maintain about you.
            </li>
            <li>
              <b>Correction.</b> You can ask us to correct inaccurate personal
              information that we maintain about you.
            </li>
            <li>
              <b>Opt-out of tracking for targeted advertising purposes.</b> You
              can opt-out of certain processing of your personal information for
              targeted advertising purposes. California law classifies our use
              of some targeted advertising services as “sharing” of your
              personal information with those services from which you have the
              right to opt-out. You can request to opt-out of this activity
              here. You can also turn on the Global Privacy Control (GPC) to opt
              out of the “sharing” of your personal information through websites
              in each participating browser that you use. Learn more at the
              Global Privacy Control website.
            </li>
            <li>
              <b>Opt-out of sales of personal information.</b> You can opt-out
              of any sales of your personal information for monetary or other
              valuable consideration.
            </li>
            <li>
              <b>Nondiscrimination.</b> You are entitled to exercise the rights
              described above free from discrimination as prohibited by
              applicable privacy laws.
            </li>
          </ul>
          <p>
            <b>Verification of Identity; Authorized Agents.</b> We may need to
            verify your identity to process your information, access, deletion,
            and correction requests, and we reserve the right to confirm your
            residency. To verify your identity, we may require authentication
            into your account on the Services, government identification, a
            declaration under penalty of perjury or other information, where
            permitted by law. Furthermore, your authorized agent may make a
            request on your behalf upon our verification of the agent’s identity
            and our receipt of a copy of a valid power of attorney given to your
            authorized agent pursuant to applicable state law. If you have not
            provided your agent with such a power of attorney, we may ask you
            and/or your agent to take additional steps permitted by law to
            verify that your request is authorized, such as information required
            to verify your identity and that you have given the authorized agent
            permission to submit the request.
          </p>
          <p>
            The rights described above are subject to exemptions and, in certain
            cases, we may decline your request as permitted by law. We cannot
            process your request if you do not provide us with sufficient detail
            to allow us to understand and respond to it. You can ask to appeal
            any denial of your request in the same manner through which you may
            submit a request.
          </p>
          <h3>Additional information for California residents</h3>
          <p>
            This section provides disclosures required by California law about
            information practices currently and during the past 12 months.
          </p>
          <p>
            Personal Information that We Collect, Use and Disclose. The list
            below summarizes our collection, use and disclosure practices for
            each category of personal information listed above in the Personal
            Information We Collect section. We may also disclose personal
            information to professional advisors, authorities and others, and
            business transferees as described above in the How we Share Your
            Personal Information section of this Privacy Policy.
          </p>
          <h3>Contact Data</h3>
          <ul>
            <li>
              <i>Sources:</i> You
            </li>
            <li>
              <i>Purposes:</i> Service delivery, Research and development,
              Marketing, Compliance and operations
            </li>
            <li>
              <i>Third parties to whom we disclose for business purposes:</i>{" "}
              Affiliates, Business partners, Service providers
            </li>
            <li>
              <i>Third parties with whom we share for targeted advertising:</i>{" "}
              Advertising partners
            </li>
          </ul>
          <h3>Registration Data</h3>
          <ul>
            <li>
              <i>Sources:</i> You
            </li>
            <li>
              <i>Purposes:</i> Service delivery, Research and development,
              Marketing, Compliance and operations
            </li>
            <li>
              <i>Third parties with whom we disclose for business purposes:</i>{" "}
              Affiliates, Business partners, Service providers
            </li>
            <li>
              <i>Third parties with whom we share for targeted advertising:</i>{" "}
              None
            </li>
          </ul>
          <h3>Communications</h3>
          <ul>
            <li>
              <i>Sources:</i> You
            </li>
            <li>
              <i>Purposes:</i> Service delivery, Research and development,
              Marketing, Compliance and operations
            </li>
            <li>
              <i>Third parties with whom we disclose for business purposes:</i>{" "}
              Affiliates, Business partners, Service providers
            </li>
            <li>
              <i>Third parties with whom we share for targeted advertising:</i>{" "}
              None
            </li>
          </ul>
          <h3>Marketing Data</h3>
          <ul>
            <li>
              <i>Sources:</i> You, Automatic collection
            </li>
            <li>
              <i>Purposes:</i> Service delivery, Research and development,
              Marketing, Compliance and operations
            </li>
            <li>
              <i>Third parties with whom we disclose for business purposes:</i>{" "}
              Affiliates, Business partners, Service providers
            </li>
            <li>
              <i>Third parties with whom we share for targeted advertising:</i>{" "}
              Advertising partners
            </li>
          </ul>
          <h3>Device Data</h3>
          <ul>
            <li>
              <i>Sources:</i> You, Automatic collection
            </li>
            <li>
              <i>Purposes:</i> Service delivery, Research and development,
              Marketing, Compliance and operations
            </li>
            <li>
              <i>Third parties with whom we disclose for business purposes:</i>{" "}
              Affiliates, Service Providers
            </li>
            <li>
              <i>Third parties with whom we share for targeted advertising:</i>{" "}
              Advertising partners
            </li>
          </ul>
          <h3>Online Activity Data</h3>
          <ul>
            <li>
              <i>Sources:</i> You, Automatic collection
            </li>
            <li>
              <i>Purposes:</i> Service delivery, Research and development,
              Marketing, Compliance and operations
            </li>
            <li>
              <i>Third parties with whom we disclose for business purposes:</i>{" "}
              Affiliates, Business partners, Service providers
            </li>
            <li>
              <i>Third parties with whom we share for targeted advertising:</i>{" "}
              Advertising partners
            </li>
          </ul>
          <h3>Data Derived from the Above Listed Information</h3>
          <ul>
            <li>
              <i>Sources:</i> May be derived from the information listed above
            </li>
            <li>
              <i>Purposes:</i> Service delivery, Research and development,
              Marketing, Compliance and operations
            </li>
            <li>
              <i>Third parties with whom we disclose for business purposes:</i>{" "}
              Affiliates, Service Providers
            </li>
            <li>
              <i>Third parties with whom we share for targeted advertising:</i>{" "}
              Advertising partners
            </li>
          </ul>
          <p>
            <b>California Categories of Personal Information.</b> California law
            requires that we describe to California residents the categories of
            personal information we collect by reference to certain categories
            described in Cal. Civ. Code Section 1798.140(v). All categories
            described in this Privacy Policy include “identifiers” and “customer
            records”, Registration Data includes “commercial information”,
            Device Data and Online Activity Data include “internet or other
            electronic network activity information”; any credentials you use to
            access the Service and your device’s precise geolocation are
            “sensitive personal information”; and Data Derived from the Above
            Listed Information includes “inferences”.
          </p>
          <p>
            <b>
              Sale of Personal Information/Use of Sensitive Personal
              Information.
            </b>{" "}
            We do not sell personal information of California residents as
            “sell” is defined under the California Consumer Privacy Act, except
            to the extent that the “sharing” of personal information for
            targeted advertising described above constitutes a “sale” of
            personal information. We do not use or disclose sensitive personal
            information (as defined under the California Consumer Privacy Act)
            of California residents for purposes subject to consumer limitation
            rights under the California Consumer Privacy Act. We do not have
            actual knowledge that we have sold or shared the personal
            information of California residents under 16 years of age.
          </p>
          <p>
            <b>Shine the Light / Your California Privacy Rights.</b> Under
            California’s Shine the Light Law (California Civil Code Section
            1798.83), California residents may also ask companies with whom you
            have formed a business relationship primarily for personal, family
            or household purposes to provide the names of third parties to which
            they have disclosed certain personal information (as defined in the
            Shine the Light Law) during the preceding year for their own direct
            marketing purposes. California residents may send requests for this
            information to privacy@tegna.com. For all requests, you must put the
            statement “Shine the Light Request” in the body of your request, and
            provide your first and last name. Please also indicate in your
            request the name of the website or other digital service to which
            your request applies. We are not responsible for notices that are
            not labeled or sent properly, or that do not have complete
            information.
          </p>
        </div>
        <Footer />
      </div>
    </>
  );
}
