// Updated this file 2/23/24
const singleTable = [
  [240, 870, 1020, 1020, 1020, 1540, 1870, 1870, 1870, 1870, 1910, 2040],
  [870, 1680, 1830, 1830, 2350, 3350, 3680, 3680, 3680, 3720, 3920, 4050],
  [1020, 1830, 1980, 2510, 3510, 4510, 4830, 4830, 4870, 5070, 5270, 5400],
  [1020, 1830, 2510, 3510, 4510, 5510, 5830, 5870, 6070, 6270, 6470, 6600],
  [1390, 3200, 4360, 5360, 6360, 7370, 7890, 8090, 8290, 8490, 8690, 8820],
  [1870, 3680, 4830, 5840, 7040, 8240, 8770, 8970, 9170, 9370, 9570, 9700],
  [1870, 3690, 5040, 6240, 7440, 8640, 9170, 9370, 9570, 9770, 9970, 10810],
  [2040, 4050, 5400, 6600, 7800, 9000, 9530, 9730, 10180, 11180, 12180, 13120],
  [
    2040, 4050, 5400, 6600, 7800, 9000, 10180, 11180, 12180, 13180, 14180,
    15310,
  ],
  [
    2040, 4050, 5400, 6860, 8860, 10860, 12180, 13180, 14230, 15530, 16830,
    18060,
  ],
  [
    2040, 4710, 6860, 8860, 10860, 12860, 14380, 15680, 16980, 18280, 19580,
    20810,
  ],
  [
    2720, 5610, 8060, 10360, 12660, 14960, 16590, 17890, 19190, 20490, 21790,
    23020,
  ],
  [
    2970, 6080, 8540, 10840, 13140, 15440, 17060, 18360, 19660, 20960, 22260,
    23500,
  ],
  [
    2970, 6080, 8540, 10840, 13140, 15440, 17060, 18360, 19660, 20960, 22260,
    23500,
  ],
  [
    3140, 6450, 9110, 11610, 14110, 16610, 18430, 19930, 21430, 22930, 24430,
    25870,
  ],
];
// TODO: remember the last case needs to be over 110,000
const singleColumns = [
  9999, 19999, 29999, 39999, 49999, 59999, 69999, 79999, 89999, 99999, 109999,
  120000,
];

// TODO: remember the last case needs to be over 450,000
const singleRows = [
  9999, 19999, 29999, 39999, 59999, 79999, 99999, 124999, 149999, 174999,
  199999, 249999, 399999, 450000,
];

const singleCalculation = (income1, income2) => {
  const higherNumber = Math.max(income1, income2);
  const lowerNumber = Math.min(income1, income2);

  let rowIndex = singleRows.findIndex((range) => higherNumber <= range);
  let columnIndex = singleColumns.findIndex((range) => lowerNumber <= range);

  // If income is higher than all ranges, use the highest available index
  if (rowIndex === -1) {
    rowIndex = singleRows.length;
  }

  if (columnIndex === -1) {
    columnIndex = singleColumns.length;
  }

  // Ensure the indices are within bounds of the singleTable
  rowIndex = Math.min(rowIndex, singleTable.length - 1);
  columnIndex = Math.min(columnIndex, singleTable[rowIndex].length - 1);

  return singleTable[rowIndex][columnIndex];
};

export { singleCalculation };
