import { PDFDocument } from "pdf-lib";
import React, { useState, useEffect } from "react";
import summaryForm from "./images/summaryForm.pdf";

async function AccountantReport(formInput) {
  const formUrl = summaryForm;
  const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(formPdfBytes);

  const form = pdfDoc.getForm();
  const income1 = form.getTextField("income1");
  const income2 = form.getTextField("income2");
  const totalIncome = form.getTextField("totalIncome");
  const dependent = form.getTextField("dependent");
  const dependentSpouse = form.getTextField("dependentSpouse");
  const totalDependents = form.getTextField("totalDependents");
  const otherIncome = form.getTextField("otherIncome");
  const otherIncomeSpouse = form.getTextField("otherIncomeSpouse");
  const totalOtherIncome = form.getTextField("totalOtherIncome");
  const otherDependents = form.getTextField("otherDependents");
  const otherDependentsSpouse = form.getTextField("otherDependentsSpouse");
  const totalOtherDependents = form.getTextField("totalOtherDependents");
  const itemizedDeductions = form.getTextField("itemizedDeductions");
  const itemizedDeductionsSpouse = form.getTextField(
    "itemizedDeductionsSpouse"
  );
  const totalDeductions = form.getTextField("totalDeductions");
  const extraWithholdingRecommended = form.getTextField(
    "extraWithholdingRecommended"
  );
  const spouseWithholdingRecommended = form.getTextField(
    "spouseWithholdingRecommended"
  );
  const totalWithholdingRecommended = form.getTextField(
    "totalWithholdingRecommended"
  );
  const extraWithholdingOverride = form.getTextField(
    "extraWithholdingOverride"
  );
  const spouseExtraWithholdingOverride = form.getTextField(
    "spouseExtraWithholdingOverride"
  );
  const totalWithholdingOverride = form.getTextField(
    "totalWithholdingOverride"
  );
  const payFrequency = form.getTextField("payFrequency");
  const spousePayFrequency = form.getTextField("spousePayFrequency");
  // let higherIncome = Number(formInput?.income1).toFixed(0);
  // let lowerIncome = Number(formInput?.income2).toFixed(0);
  // if (formInput?.income2 > formInput?.income1) {
  //   higherIncome = Number(formInput?.income2).toFixed(0);
  //   lowerIncome = Number(formInput?.income1).toFixed(0);
  //   return;
  // }

  const formattedIncome1 = formInput?.income1
    ? Number(formInput.income1).toLocaleString()
    : "";
  income1.setText(formattedIncome1);

  const formattedIncome2 = formInput?.income2
    ? Number(formInput.income2).toLocaleString()
    : "";
  income1.setText(formattedIncome1);
  income2.setText(formattedIncome2);
  const totalIncomeCalculation = (
    Number(formInput?.income1 || 0) + Number(formInput?.income2 || 0)
  ).toFixed(0);

  const formattedTotalIncome = Number(totalIncomeCalculation).toLocaleString();

  totalIncome.setText(formattedTotalIncome);

  const childrenCalculation = Number(formInput?.children * 2000);
  const formattedChildrenCalculation =
    Number(childrenCalculation).toLocaleString();

  const childrenSpouseCalculation = Number(formInput?.childrenSpouse * 2000);

  const formattedChildrenSpouseCalculation =
    childrenSpouseCalculation.toLocaleString();
  const totalDependentCalculation =
    Number(childrenCalculation) + Number(childrenSpouseCalculation);
  const formattedTotalDependentCalculation =
    totalDependentCalculation.toLocaleString();
  const otherDependentsCalculation = Number(formInput?.otherDependents * 500);
  const formattedOtherDependentsCalculation =
    otherDependentsCalculation.toLocaleString();
  const otherDependentsSpouseCalculation = Number(
    formInput?.otherDependentsSpouse * 500
  );
  const formattedOtherDependentsSpouseCalculation =
    otherDependentsSpouseCalculation.toLocaleString();
  const totalOtherDependentCalculation =
    Number(otherDependentsCalculation) +
    Number(otherDependentsSpouseCalculation);
  const formattedTotalOtherDependentcalculation =
    totalOtherDependentCalculation.toLocaleString();
  if (totalIncomeCalculation < 400000) {
    dependent.setText(formattedChildrenCalculation);
    dependentSpouse.setText(formattedChildrenSpouseCalculation);
    totalDependents.setText(formattedTotalDependentCalculation);
    otherDependents.setText(formattedOtherDependentsCalculation);
    otherDependentsSpouse.setText(formattedOtherDependentsSpouseCalculation);
    totalOtherDependents.setText(formattedTotalOtherDependentcalculation);
  } else {
    dependent.setText("0");
    dependentSpouse.setText("0");
    totalDependents.setText("0");
    otherDependents.setText("0");
    otherDependentsSpouse.setText("0");
    totalOtherDependents.setText("0");
  }

  const formattedOtherIncome = formInput?.otherIncome
    ? Number(formInput.otherIncome).toLocaleString()
    : "";
  income1.setText(formattedIncome1);

  otherIncome.setText(formattedOtherIncome);
  const formattedOtherIncomeSpouse = formInput?.otherIncomeSpouse
    ? Number(formInput.otherIncomeSpouse).toLocaleString()
    : "";
  otherIncomeSpouse.setText(formattedOtherIncomeSpouse);

  const totalOtherIncomeCalculation =
    Number(formInput?.otherIncome) + Number(formInput?.otherIncomeSpouse);

  const formattedTotalOtherIncome =
    totalOtherIncomeCalculation.toLocaleString();
  totalOtherIncome.setText(formattedTotalOtherIncome);

  const formattedItemizedDeductions = formInput?.amountToItemize
    ? Number(formInput.amountToItemize).toLocaleString()
    : "";
  itemizedDeductions.setText(formattedItemizedDeductions);
  const formattedItemizedDeductionsSpouse = formInput?.amountToItemizeSpouse
    ? Number(formInput.amountToItemizeSpouse).toLocaleString()
    : "";
  itemizedDeductions.setText(formattedItemizedDeductions);
  itemizedDeductionsSpouse.setText(formattedItemizedDeductionsSpouse);

  const formattedTotalDeductions = formInput?.totalAmountToItemize
    ? Number(formInput.totalAmountToItemize).toLocaleString()
    : "";
  totalDeductions.setText(formattedTotalDeductions);

  payFrequency.setText(formInput?.paycheckFrequency);
  spousePayFrequency.setText(formInput?.paycheckFrequencySpouse);
  const formattedExtraWithholdingRecommended =
    formInput?.withholdingPrimaryRecommended
      ? Number(formInput.withholdingPrimaryRecommended).toLocaleString()
      : "";
  totalDeductions.setText(formattedTotalDeductions);
  extraWithholdingRecommended.setText(
    String(`${formattedExtraWithholdingRecommended} / pay period`)
  );
  const formattedSpouseWithholdingRecommended =
    formInput?.withholdingSpouseRecommended
      ? Number(formInput.withholdingSpouseRecommended).toLocaleString()
      : "";
  spouseWithholdingRecommended.setText(
    String(`${formattedSpouseWithholdingRecommended} / pay period`)
  );

  const formattedTotalWithholdingRecommended = formInput?.withholdingAmount
    ? Number(formInput.withholdingAmount).toLocaleString()
    : "";
  totalWithholdingRecommended.setText(
    `${formattedTotalWithholdingRecommended} / year`
  );
  const formattedExtraWithholdingOverride = formInput?.withholdingPrimary
    ? Number(formInput.withholdingPrimary).toLocaleString()
    : "";
  extraWithholdingOverride.setText(
    String(`${formattedExtraWithholdingOverride} / pay period`)
  );
  const formattedSpouseWithholdingOverride = formInput?.withholdingSpouse
    ? Number(formInput.withholdingSpouse).toLocaleString()
    : "";

  spouseExtraWithholdingOverride.setText(
    `${formattedSpouseWithholdingOverride} / pay period`
  );
  const paycheckFrequencyNumber = () => {
    switch (formInput?.paycheckFrequency) {
      case "weekly":
        return 52;

      case "monthly":
        return 12;

      case "biweekly":
        return 26;

      case "bimonthly":
        return 24;

      default:
        return 1;
    }
  };
  const spousePayFrequencyNumber = () => {
    switch (formInput.paycheckFrequencySpouse) {
      case "weekly":
        return 52;

      case "monthly":
        return 12;

      case "biweekly":
        return 26;

      case "bimonthly":
        return 24;

      default:
        return 1;
    }
  };
  const totalWithholdingOverrideAmount = () => {
    const primaryTotal =
      Number(formInput?.withholdingPrimary) * Number(paycheckFrequencyNumber());
    const spouseTotal =
      Number(formInput?.withholdingSpouse) * Number(spousePayFrequencyNumber());

    return Number(primaryTotal) + Number(spouseTotal);
  };
  const grandTotal = totalWithholdingOverrideAmount().toLocaleString();
  totalWithholdingOverride.setText(`${String(grandTotal)} / year`);
  // const currentDate = new Date();
  // const formattedDate = currentDate.toLocaleDateString("en-US", {
  //   year: "numeric",
  //   month: "long",
  //   day: "numeric",
  // });

  // date.setText(formattedDate);

  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: "application/pdf" });
  const docUrl = URL.createObjectURL(blob);
  return docUrl;
}

function FillReport({ formInput }) {
  const [accountantInfo, setAccountantInfo] = useState();

  useEffect(() => {
    const fillAndLoadPDF = async () => {
      const pdfUrl = await AccountantReport(formInput);
      setAccountantInfo(pdfUrl);
    };

    fillAndLoadPDF();
  }, [formInput]);

  return (
    <>
      {accountantInfo && (
        <object
          data={accountantInfo}
          type="application/pdf"
          style={{ width: "100%", height: "100vh" }}
        >
          <p>
            It appears your browser does not support embedded PDFs. Please{" "}
            <a href={accountantInfo} target="_blank" rel="noreferrer">
              click here
            </a>{" "}
            to view the Accountant Report.
          </p>
        </object>
      )}
    </>
  );
}

export default FillReport;

//   download form:
// var blob = new Blob([pdfBytes], {type: "application/pdf"});
// var link = document.createElement("a");
// link.href = window.URL.createObjectURL(blob);
// link.download = "myFileName.pdf";
// link.click();
