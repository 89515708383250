import React, { useState } from "react";
import ReactGA from "react-ga4";
import questionGraphic from "./images/question-mark-circle-4-128.png";
import styles from "./Interview.module.css";

export default function OtherCredits({
  otherCreditsCloseButton,
  handleChange,
  formValues,
}) {
  const [credits, setCredits] = useState();
  const otherCreditsAlert = () => {
    alert(
      "Include other tax credits for which you are eligible in this step, such as the foreign tax credit, education tax credits, or child care credits. This will reduce the tax withheld by your employer. Including these credits will increase your paycheck and reduce the amount of any refund you may receive when you file your tax return."
    );
  };
  const makeChange = (evt) => {
    setCredits({
      ...credits,
      [evt.target.name]: evt.target.value,
    });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    handleChange(credits);
    ReactGA.event({
      category: "User",
      action: "Completed other credits",
    });
    otherCreditsCloseButton();
  };
  return (
    <div className={styles.questionContainer}>
      <div className={styles.headerContainer}>
        <h3>Other Credits</h3>
        <img
          onClick={otherCreditsAlert}
          alt="question mark"
          className={styles.question}
          src={questionGraphic}
        />
      </div>
      <div onClick={makeChange}>
        <form onSubmit={handleSubmit}>
          {formValues.processSpouseW4 ? (
            <>
              <p>Credits for Spouse 1's form</p>
              <input
                defaultValue={formValues.credits}
                name="credits"
                onChange={makeChange}
                onFocus={(e) => {
                  e.target.value = "";
                }}
              ></input>
              <p>Credits for Spouse 2's form</p>
              <input
                defaultValue={formValues.creditsSpouse}
                name="creditsSpouse"
                onChange={makeChange}
                onFocus={(e) => {
                  e.target.value = "";
                }}
              ></input>
            </>
          ) : (
            <input name="credits" onChange={makeChange}></input>
          )}
          <div className={styles.nextButton}>
            <button onClick={handleSubmit}>NEXT</button>
          </div>
        </form>
      </div>
    </div>
  );
}
