import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import styles from "./Interview.module.css";
import logo from "./images/logo.png";

const dummyData = [
  {
    date: "April 30, 2023",
    url: "https://www.freecodecamp.org/news/how-to-render-lists-in-react/",
  },
  {
    date: "March 7, 2023",
    url: "https://stackoverflow.com/questions/48924672/can-i-use-href-tag-in-reactjs",
  },
  {
    date: "February 12, 2020",
    url: "https://www.figma.com/file/2cdTVJ9KKLaxBimU5d8Roy/W4EZ.me?type=design&node-id=0-1&mode=design&t=LKFLjEoiFqK0PBgU-0",
  },
];
export default function Dashboard() {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.header}>
        <div className={styles.logo} onClick={() => navigate("/")}>
          <img className={styles.logoPic} src={logo} alt="logo" />
        </div>
      </div>
      <div className={styles.interviewContainer}>
        {dummyData.map(function (data) {
          return (
            <div className={styles.dashboardRow}>
              <p className={styles.bigText}>{data.date}</p>
              <button className={styles.nextButton}>
                <a
                  className={styles.white}
                  href={data.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  VIEW
                </a>
              </button>
            </div>
          );
        })}
        <div className={styles.nextButton}>
          <button onClick={() => navigate("/interview")}>START NEW W4</button>
        </div>
      </div>
      <Footer />
    </>
  );
}
